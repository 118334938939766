<template>
    <project-list type="3"></project-list>
</template>

<script>
import projectList from "../project-list"
export default {
    components:{
        projectList
    }
}
</script>